import { polygonCentroid } from 'd3-polygon'
import { DateTime } from 'luxon'
import { createDatetime, createInterval } from './date-utils'
import { formatCampusName, formatTag, formatTagValue } from './format'
import { naturalSort } from './sort'
import { addItemToDictionaryList, addItemToDictionarySet } from './select-utils'
import { isValidTag } from './tag-utils'

const tomorrow = createDatetime().startOf('day').plus({ days: 1 })
const tomorrowISO = tomorrow.toISODate()

function makeCampus (id, buildings) {
  console.assert(buildings.every(Boolean))
  if (buildings.length === 0) {
    return null
  }

  return {
    buildings,
    id,
    name: buildings[0].campus_name,
    timezone: buildings[0].timezone,
    type: 'campus'
  }
}

export class Store {
  #building_periods = []
  #floor_periods = []
  #zone_periods = []

  #building_periods_by_id = new Map()
  #floor_periods_by_id = new Map()
  #zone_periods_by_id = new Map()

  #campus_ids = new Set()
  #building_ids = new Set()
  #floor_ids = new Set()
  #zone_ids = new Set()

  // building ID -> campus ID
  #campus_ids_by_building_id = new Map()

  // campus name -> campus ID
  #campus_ids_by_campus_name = new Map()

  // campus ID -> building ID's
  #building_ids_by_campus_id = new Map()

  // campus ID -> floor ID's
  #floor_ids_by_campus_id = new Map()

  // building ID -> floor ID's
  #floor_ids_by_building_id = new Map()

  // floor ID -> zone ID's
  #zone_ids_by_floor_id = new Map()

  // building ID -> zone ID's
  #zone_ids_by_building_id = new Map()

  // campus ID -> zone ID's
  #zone_ids_by_campus_id = new Map()

  #zone_ids_by_tag = new Map()

  constructor (buildings, zones, building_settings) {
    console.time('create Store')

    // process buildings & floors
    buildings.forEach(({ building_periods, campus_id, ...building }) => {
      this.#building_ids.add(building.id)

      // create building periods
      building_periods.forEach(({ floors, ...period }) => {
        let campusName = period.tags.find(t => t.startsWith('campus:'))
        let settings = building_settings?.find(s => s.building === building.id) ?? {}
        if (!period.zone_layers.some(({ name }) => name === settings.default_layer)) {
          console.warn(`${period.name} ${period.startdate}-${period.enddate} has invalid default_layer "${settings.default_layer}", available: ${period.zone_layers.map(l => l.name).join(', ')}`)
          settings = {
            ...settings,
            default_layer: period.zone_layers[0].name
          }
        }

        let result = {
          ...building,
          ...period,
          campus_id,
          campus_name: formatCampusName(campusName),
          interval: createInterval(period.startdate, period.enddate ?? tomorrow),
          type: 'building',
          settings
        }

        this.#building_periods.push(result)
        addItemToDictionaryList(this.#building_periods_by_id, building.id, result)
        addItemToDictionarySet(this.#building_ids_by_campus_id, campus_id, building.id)
        this.#campus_ids_by_campus_name.set(campusName, campus_id)
        this.#campus_ids.add(campus_id)
        this.#campus_ids_by_building_id.set(building.id, campus_id)
      })

      // create uniq floor periods
      let building_floors = new Map()
      building_periods.forEach(period => {
        period.floors.forEach(floor => {
          if (!building_floors.has(floor.id)) {
            building_floors.set(floor.id, floor)
          }

          let existing = building_floors.get(floor.id)
          floor.floor_periods.forEach(period => {
            // add new floor periods
            if (existing.floor_periods.some(p => p.start !== period.start || p.end !== period.end)) {
              existing.floor_periods.push(period)
            }
          })
        })
      })

      Array.from(building_floors.values()).forEach(({ floor_periods, ...floor }, index, floors) => {
        this.#floor_ids.add(floor.id)
        addItemToDictionarySet(this.#floor_ids_by_building_id, building.id, floor.id)
        let campus_id = this.#campus_ids_by_building_id.get(building.id)
        let campusName = building_periods[0].tags.find(t => t.startsWith('campus:'))
        addItemToDictionarySet(this.#floor_ids_by_campus_id, campus_id, floor.id)

        floor_periods
          // only process floors that have at least 1 zone defined
          .filter(period => zones.some(zone => zone.floor_id === floor.id))
          .forEach(( period) => {
            let previous = floors[index - 1]
            let level = getFloorLevel(period.name, previous?.floor_periods[0].name)
            console.assert(Number.isFinite(level), { building: building_periods[0], period, level })
            let result = {
              building_id: building.id,
              building_name: building_periods[0].name,
              campus_id,
              campus_name: formatCampusName(campusName),
              ...floor,
              ...period,
              interval: createInterval(period.startdate, period.enddate ?? tomorrow),
              level,
              name: Number.isFinite(level) ? `Floor ${level}` : period.name,
              shortname: level,
              timezone: building.timezone,
              type: 'floor'
            }

            this.#floor_periods.push(result)
            addItemToDictionaryList(this.#floor_periods_by_id, floor.id, result)
          })
      })
    })

    /* Zones */
    zones.forEach(({ zone_periods, ...zone }) => {
      this.#zone_ids.add(zone.id)
      addItemToDictionarySet(this.#zone_ids_by_floor_id, zone.floor_id, zone.id)
      addItemToDictionarySet(this.#zone_ids_by_building_id, zone.building_id, zone.id)

      zone_periods.forEach(
        period => {
          // Get the last matching building & floor period so we can merge in their names & id's
          // NOTE - don't merge in these objects directly since multiple periods could match
          // and this leads to inconsistent results. See https://lonerooftop.atlassian.net/browse/PIE-2551
          let building = this.building(zone.building_id, period.startdate, period.enddate)
          let floor = this.floor(zone.floor_id, period.startdate, period.enddate)
          let normalised_centroid = polygonCentroid(period.normalised_points)

          if (!floor || !building) {
            console.error({zone, period, floor, building })
            throw new Error(`Zones mis-configuration - please contact your CSM. Detected a zone without corresponding building or floor`)
          }

          let denormalize = ([x, y]) => [
            Math.round(x * floor.floorplanimagedimensions[0]),
            Math.round(y * floor.floorplanimagedimensions[1]),
          ]

          let points = closePolygons(period.normalised_points).map(denormalize)

          let result = {
            ...zone,
            ...period,
            campus_name: building.campus_name,
            campus_id: building.campus_id,
            building_name: building.name,
            default_layer: building.settings.default_layer,
            floor_name: floor.name,
            capacity: new Map(period.capacity),
            points,
            // this leads to slightly better label positioning, but the cost of (pre) computing this is significant
            // polylabel: polylabel([points], 1.0),
            centroid: denormalize(normalised_centroid),
            normalised_centroid,
            interval: createInterval(period.startdate, period.enddate ?? tomorrow),
            tags: period.tags
              .map(tag => tag.toLowerCase())
              .filter(tag =>
                isValidTag(tag)
                && !tag.startsWith('custom')
                && !tag.startsWith('hide')
                && !tag.startsWith('uid')
              ),
            timezone: building.timezone,
            type: 'zone',
          }
          this.#zone_periods.push(result)
          addItemToDictionaryList(this.#zone_periods_by_id, zone.id, result)
          addItemToDictionarySet(this.#zone_ids_by_campus_id, result.campus_id, zone.id)
          result.tags.forEach(tag => {
            addItemToDictionaryList(this.#zone_ids_by_tag, tag, zone.id)
          })
        }
      )
    })

    // Remove any building-period-level zone_layers if none of the zones matching that period contain the layer
    // @TODO: consider moving this check to the backend
    this.#building_periods.forEach(building_period => {
      let zone_periods = this.#zone_periods.filter(
        zone_period => zone_period.building_id === building_period.id
        && zone_period.interval.overlaps(building_period.interval)
      )

      let zones_per_layer = new Map(Array.from(
        new Set(zone_periods.map(zone_period => zone_period.layer_name)),
        layer_name => [
          layer_name,
          zone_periods.filter(zone_period => zone_period.layer_name === layer_name) ?? []
        ]
      ))

      building_period.zone_layers = building_period.zone_layers.filter(
        layer => {
          if (!zones_per_layer.has(layer.name) || zones_per_layer.get(layer.name).length === 0) {
            console.warn(`${building_period.name} period ${building_period.startdate}-${building_period.enddate} contains zone layer ${layer.name} but no zones exists with that name`, { zone_layers: {...building_period.zone_layers}, zones_per_layer })
          }
          return zones_per_layer.has(layer.name) && zones_per_layer.get(layer.name).length > 0
        }
      )

      Object.freeze(building_period)
    })

    console.timeEnd('create Store')
  }

  campus (id, startdate, enddate) {
    let campusId = this.getCampusId(id)
    if (this.#campus_ids.has(campusId)) {
      return makeCampus(campusId, this.buildings(campusId, startdate, enddate))
    }
  }

  campuses (spaceId, startdate, enddate) {
    if (!spaceId) {
      return Array.from(
        this.#campus_ids,
        id => makeCampus(id, this.buildings(id, startdate, enddate))
      ).filter(Boolean)
    } else {
      let campusId = this.getCampusId(spaceId)
      if (this.#campus_ids.has(campusId)) {
        return [makeCampus(campusId, this.buildings(campusId, startdate, enddate))].filter(Boolean)
      }
    }
  }

  building (id, startdate, enddate) {
    if (this.#building_ids.has(id)) {
      return lastOverlappingPeriod(this.#building_periods_by_id.get(id), startdate, enddate)
    }
  }

  buildings (spaceId, startdate, enddate) {
    // no ID => [all buildings]
    if (!spaceId) {
      return Array.from(
        this.#building_periods_by_id,
        ([id, periods]) => lastOverlappingPeriod(periods, startdate, enddate)
      ).filter(Boolean)
    }

    // campus ID => [buildings]
    let campusId = this.getCampusId(spaceId)
    if (this.#building_ids_by_campus_id.has(campusId) || this.#building_ids_by_campus_id.has(`campus:${spaceId}`)) {
      return Array.from(
        this.#building_ids_by_campus_id.get(campusId) || this.#building_ids_by_campus_id.get(`campus:${spaceId}`),
        id => lastOverlappingPeriod(this.#building_periods_by_id.get(id), startdate, enddate)
      ).filter(Boolean)
    }

    // building ID => [building]
    if (this.#building_periods_by_id.has(spaceId)) {
      return [lastOverlappingPeriod(this.#building_periods_by_id.get(spaceId), startdate, enddate)].filter(Boolean)
    }

    // building ID's -> buildings
    if (Array.isArray(spaceId)) {
      return spaceId.map(id => this.building(id, startdate, enddate)).filter(Boolean)
    }

    // unknown ID
    return []
  }

  floor (id, startdate, enddate) {
    if (this.#floor_periods_by_id.has(id)) {
      return lastOverlappingPeriod(this.#floor_periods_by_id.get(id), startdate, enddate)
    }
  }

  /* get floor or floors by building ID, floor ID or zone ID */
  floors (spaceId, startdate, enddate) {
    // no ID -> all floors
    if (!spaceId) {
      return Array.from(
        this.#floor_ids,
        id => lastOverlappingPeriod(this.#floor_periods_by_id.get(id), startdate, enddate)
      ).filter(Boolean)
    }

    // campus ID -> floors
    let campusId = this.getCampusId(spaceId)
    if (this.#floor_ids_by_campus_id.has(campusId)) {
      return Array.from(
        this.#floor_ids_by_campus_id.get(campusId),
        id => lastOverlappingPeriod(this.#floor_periods_by_id.get(id), startdate, enddate)
      ).filter(Boolean)
    }

    // building ID -> floors
    if (this.#floor_ids_by_building_id.has(spaceId)) {
      return Array.from(
        this.#floor_ids_by_building_id.get(spaceId),
        id => lastOverlappingPeriod(this.#floor_periods_by_id.get(id), startdate, enddate)
      ).filter(Boolean)
    }

    // floor ID -> floor
    if (this.#floor_periods_by_id.has(spaceId)) {
      return [lastOverlappingPeriod(this.#floor_periods_by_id.get(spaceId), startdate, enddate)]
    }

    // zone ID -> floor
    if (this.#zone_periods_by_id.has(spaceId)) {
      let zone = lastOverlappingPeriod(this.#zone_periods_by_id.get(spaceId), startdate, enddate)
      if (zone?.floor_id) {
        return [lastOverlappingPeriod(this.#floor_periods_by_id.get(zone.floor_id), startdate, enddate)].filter(Boolean)
      } else {
        throw new Error('Tried to get floor ID from zone, but no matching zone period was found')
      }
    }

    // floor ID's -> floors
    if (Array.isArray(spaceId)) {
      return spaceId.map(id => this.floor(id, startdate, enddate)).filter(Boolean)
    }

    // unkown space ID
    return []
  }

  zone (id, startdate, enddate) {
    if (this.#zone_ids.has(id)) {
      return lastOverlappingPeriod(this.#zone_periods_by_id.get(id), startdate, enddate)
    }
  }

  /* get zone or zones by building ID, floor ID or zone ID */
  zones (spaceId, startdate, enddate) {
    // no ID -> all zones
    if (!spaceId) {
      return Array.from(
        this.#zone_ids,
        id => lastOverlappingPeriod(this.#zone_periods_by_id.get(id), startdate, enddate)
      ).filter(Boolean)
    }

    // campus ID -> zones
    let campusId = this.getCampusId(spaceId)
    if (this.#zone_ids_by_campus_id.has(campusId)) {
      return Array.from(
        this.#zone_ids_by_campus_id.get(campusId),
        id => lastOverlappingPeriod(this.#zone_periods_by_id.get(id), startdate, enddate)
      ).filter(Boolean)
    }

    // building ID -> zones
    if (this.#zone_ids_by_building_id.has(spaceId)) {
      return Array.from(
        this.#zone_ids_by_building_id.get(spaceId),
        id => lastOverlappingPeriod(this.#zone_periods_by_id.get(id), startdate, enddate)
      ).filter(Boolean)
    }

    // floor ID -> zones
    if (this.#zone_ids_by_floor_id.has(spaceId)) {
      return Array.from(
        this.#zone_ids_by_floor_id.get(spaceId),
        id => lastOverlappingPeriod(this.#zone_periods_by_id.get(id), startdate, enddate )
      ).filter(Boolean)
    }

    // zone ID -> zones
    if (this.#zone_periods_by_id.has(spaceId)) {
      return [lastOverlappingPeriod(this.#zone_periods_by_id.get(spaceId), startdate, enddate)].filter(Boolean)
    }

    // zone ID's -> zones
    if (Array.isArray(spaceId)) {
      return spaceId
        .filter(id => {
          if (this.#zone_ids.has(id)) {
            return true
          } else {
            console.warn('unknown zone ID', id)
            return false
          }
        })
        .map(id => this.zone(id, startdate, enddate))
    }

    // unknown space ID
    console.warn('unknown space ID', spaceId)
    return []
  }

  zoneGroup (tag, spaceId, startdate, enddate) {
    let space = {}
    let zones = this.zones(spaceId, startdate, enddate)
    if (zones.length === 0 || !isValidTag(tag)) {
      return null
    }
    console.assert(
      zones.every(zone => zone.tags.includes(tag)),
      { tag, zones }
    )
    // zone groups may be from different buildings
    space.building_ids = new Set(zones.map(zone => zone.building_id))
    space.building_id = space.building_ids.size === 1
      ? zones[0].building_id
      : null
    space.building_name = space.building_ids.size === 1
      ? zones[0].building_name
      : null
    space.campus_name = zones[0].campus_name
    space.floor_ids = new Set(zones.map(zone => zone.floor_id))
    space.floor_id = space.floor_ids.size === 1
      ? zones[0].floor_id
      : null
    space.floor_name = space.floor_ids.size === 1
      ? zones[0].floor_name
      : null
    space.id = tag
    space.name = formatTag(tag)
    space.short_name = formatTagValue(tag)
    space.timezone = zones[0].timezone
    space.type = 'tag'
    space.zones = zones
    space.zone_ids = zones.map(zone => zone.id)
    return space
  }

  get (id, startdate, enddate) {
    if (!id) {
      throw new RangeError('store.get() requires an ID')
    }

    if (!this.has(id)) {
      console.debug('Unknown ID: ', { id })
      return null
    }

    let campusId = this.getCampusId(id)
    if (this.#campus_ids.has(campusId)) {
      return this.campus(campusId, startdate, enddate)
    }

    let periods = this.getPeriods(id)
    if (!periods) return null

    return lastOverlappingPeriod(periods, startdate, enddate)
  }

  getAll (ids, startdate, enddate) {
    if (Array.isArray(ids)) {
      return ids.map(id => this.get(id, startdate, enddate)).filter(Boolean)
    }
  }

  getSpaceIds (key) {
    switch (key) {
      case 'campus':
        return this.#campus_ids
      case 'building':
        return this.#building_ids
      case 'floor':
        return this.#floor_ids
      case 'zone':
        return this.#zone_ids
      default:
        throw new Error('Unknown space type: ' + key)
    }
  }

  getSpacePeriods (key) {
    switch (key) {
      case 'building':
        return this.#building_periods
      case 'building_by_id':
        return this.#building_periods_by_id
      case 'floor':
        return this.#floor_periods
      case 'floor_by_id':
        return this.#floor_periods_by_id
      case 'zone':
        return this.#zone_periods
      case 'zone_by_id':
        return this.#zone_periods_by_id
      default:
        throw new Error('Unknown space periods key: ' + key)
    }
  }

  getPeriods (id, startdate, enddate) {
    if (!startdate && !enddate) {
      let campusId = this.getCampusId(id)
      if (this.#campus_ids.has(campusId)) {
        return this.#building_periods.filter(b => b.campus_id === campusId)
      } else if (this.#building_periods_by_id.has(id)) {
        return this.#building_periods_by_id.get(id)
      } else if (this.#floor_periods_by_id.has(id)) {
        return this.#floor_periods_by_id.get(id)
      } else if (this.#zone_periods_by_id.has(id)) {
        return this.#zone_periods_by_id.get(id)
      } else {
        return []
      }
    }

    let periods = this.getPeriods(id)
    let interval = createInterval(startdate, enddate)
    if (!interval.isValid) {
      throw new RangeError('invalid startdate or enddate')
    }

    return periods.filter(period => period.interval.overlaps(interval))
  }

  getType (id) {
    if (this.#campus_ids.has(id) || this.#campus_ids_by_campus_name.has(id)) {
      return 'campus'
    } else if (this.#building_ids.has(id)) {
      return 'building'
    } else if (this.#floor_ids.has(id)) {
      return 'floor'
    } else if (this.#zone_ids.has(id)) {
      return 'zone'
    } else {
      return ''
    }
  }

  getHierarchy (id, startdate, enddate) {
    let type = this.getType(id)
    let campus, building, floor, zone

    switch (type) {
      case 'campus':
        campus = this.campus(id, startdate, enddate)
        break
      case 'building':
        building = this.building(id, startdate, enddate)
        campus = building ? this.campus(building.campus_id, startdate, enddate) : null
        break
      case 'floor':
        floor = this.floor(id, startdate, enddate)
        building = floor ? this.building(floor.building_id, startdate, enddate) : null
        campus = building ? this.campus(building.campus_id, startdate, enddate) : null
        break
      case 'zone':
        zone = this.zone(id, startdate, enddate)
        floor = zone ? this.floor(zone.floor_id, startdate, enddate) : null
        building = floor ? this.building(floor.building_id, startdate, enddate) : null
        campus = building ? this.campus(building.campus_id, startdate, enddate) : null
        break
      default:
        // throw new Error('Unknown space type: ' + type)
        break
    }

    return { campus, building, floor, zone, space: zone ?? floor ?? building ?? campus }
  }

  // Returns campus id
  // if the input id is the campus name, return its uuid
  getCampusId (id) {
    let campusId = (this.#campus_ids_by_campus_name.get(id) || this.#campus_ids_by_campus_name.get(`campus:${id}`)) ?? id

    return campusId
  }

  has (id, startdate, enddate) {
    if (startdate && enddate) {
      let periods = this.getPeriods(id)
      return Boolean(lastOverlappingPeriod(periods, startdate, enddate))
    } else {
      return this.#campus_ids.has(id) || this.#campus_ids_by_campus_name.has(id) || this.#building_ids.has(id) || this.#floor_ids.has(id) || this.#zone_ids.has(id)
    }
  }

  // input: id (string) or ids (array). Optional, will fall back to all buildings.
  // output: [startdate (string), enddate (string)] earliest and latest dates that are valid periods
  getMinMaxDate (input) {
    let periods
    if (Array.isArray(input) && input.length > 0) {
      periods = input.flatMap(id => this.getPeriods(id)).filter(Boolean)
    } else if (typeof input === 'string') {
      periods = this.getPeriods(input)
    } else {
      periods = this.#building_periods
    }

    if (periods.length === 0) return []
    let startdate = periods.map(period => period.startdate).sort(naturalSort).filter(Boolean)[0]
    let enddate = periods.map(period => period.enddate ?? tomorrowISO).sort(naturalSort).filter(Boolean).reverse()[0]
    return [startdate, enddate]
  }

  updateBuildingSettings (building_settings) {
    let updatedBuildingPeriodsById = new Map()
    let updatedBuildingPeriods = this.#building_periods.map(buildingPeriod => {
      let settings = building_settings.find(s => s.building === buildingPeriod.id)

      let updatedBuildingPeriod = buildingPeriod

      if (settings) {
        if (Array.isArray(settings.excluded_periods) && settings.excluded_periods.length > 0) {
          settings.excluded_periods.forEach(period => {
            period.startdatetime = DateTime.fromISO(period.startdate)
            period.enddatetime = period.enddate === null ? tomorrow : DateTime.fromISO(period.enddate)
            period.interval = createInterval(period.startdate, period.enddate ?? tomorrow)
          })
        }
        
        updatedBuildingPeriod = {
          ...buildingPeriod,
          settings
        }

      }
      
      addItemToDictionaryList(updatedBuildingPeriodsById, buildingPeriod.id, updatedBuildingPeriod)
      return updatedBuildingPeriod
    })

    this.#building_periods = updatedBuildingPeriods
    this.#building_periods_by_id = updatedBuildingPeriodsById
  }
}

function lastOverlappingPeriod (periods = [], startdate, enddate) {
  // don't do any work if we don't have to
  if (!startdate && !enddate) {
    return periods[periods.length - 1]
  }

  let interval = createInterval(startdate, enddate)
  return periods.findLast(period => period.interval.overlaps(interval))
}

function getFloorLevel (name, prevName) {
  let matches = name.match(/-?\d+/g)
  if (matches) {
    return parseInt(matches[0], 10)
  }

  switch (name.toLowerCase().replace(/ .*/, '')) {
    case 'basement':    return - 1
    case 'ground':
    case 'lower':
    case 'bg':          return 0
    case 'first':       return 1
    case 'second':      return 2
    case 'third':       return 3
    case 'fourth':      return 4
    case 'fifth':       return 5
    case 'sixth':       return 6
    case 'seventh':     return 7
    case 'eigth':       return 8
    case 'nineth':      return 9
    default:            break
    // default:            return name
  }

  // just increment the previous level if we can
  if (prevName) {
    let prevLevel = getFloorLevel(prevName)
    if (Number.isFinite(prevLevel)) {
      return prevLevel++
    }
  }

  return name
}

/**
 * Ensures that the last point is the same as the first point
 * Required for pointInPolygon
 **/
function closePolygons (polygon) {
  let length = polygon.length
  if (length > 0 && polygon[length - 1][0] !== polygon[0][0]) {
    polygon.push(polygon[0])
  }
  return polygon
}