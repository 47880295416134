import {
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  useLayoutEffect,
  useContext,
  useState,
  useMemo
} from 'react'

import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  FloatingFocusManager,
  useId
} from '@floating-ui/react'

import { Flex } from '@lonerooftop/kitt-ui'
import { CardWrapper, CardTitle } from './cards'
import { Button } from './button'

export function usePopover({
  initialOpen = false,
  placement = 'bottom',
  modal,
  open: controlledOpen,
  onOpenChange: setControlledOpen
} = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen)
  const [labelId, setLabelId] = useState()
  const [descriptionId, setDescriptionId] = useState()

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'end',
        padding: 5
      }),
      shift({ padding: 5 })
    ]
  })

  const context = data.context

  const click = useClick(context, {
    enabled: controlledOpen == null
  })
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const interactions = useInteractions([click, dismiss, role])

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      modal,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId
    }),
    [open, setOpen, interactions, data, modal, labelId, descriptionId]
  )
}

const PopoverContext = createContext(null)

export const usePopoverContext = () => {
  const context = useContext(PopoverContext)

  if (context == null) {
    throw new Error('Popover components must be wrapped in <Popover />')
  }

  return context
}

/**
 * Popover wrapper component that provides context to its children.
 *
 * @param {React.ReactNode} props.children - Child components.
 * @param {Object} props.options - Options for the popover (initialOpen, placement, modal, open, onOpenChange).
 * @returns {JSX.Element} - A provider for the popover context.
 */

export function Popover({
  children,
  modal = false,
  ...restOptions
}) {
  const popover = usePopover({ modal, ...restOptions })
  return (
    <PopoverContext.Provider value={popover}>
      {children}
    </PopoverContext.Provider>
  )
}

/**
 * Trigger component for opening the popover.
 *
 * @param {React.ReactNode} props.children - Child components.
 * @param {boolean} [props.asChild=false] - Pass a custom element as the trigger.
 * @returns {JSX.Element} - A trigger element for the popover.
 */

export const PopoverTrigger = forwardRef(function PopoverTrigger({ children, asChild = false, ...props }, propRef) {
  const context = usePopoverContext()
  const childrenRef = children.ref
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

  if (asChild && isValidElement(children)) {
    return cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed'
      })
    )
  }

  return (
    <button
      ref={ref}
      type='button'
      // The user can style the trigger based on the state
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  )
})

/**
 * Component for rendering the popover content.
 *
 * @param {React.ReactNode} props.children - Child components.
 * @returns {JSX.Element|null} - The popover content or null if the popover is closed.
 */

export const PopoverContent = forwardRef(function PopoverContent({ style, ...props }, propRef) {
  const { context: floatingContext, ...context } = usePopoverContext()
  const ref = useMergeRefs([context.refs.setFloating, propRef])

  if (!floatingContext.open) return null

  return (
    <FloatingPortal>
      <FloatingFocusManager context={floatingContext} modal={context.modal}>
        <CardWrapper
          ref={ref}
          style={{ ...context.floatingStyles, ...style }}
          aria-labelledby={context.labelId}
          aria-describedby={context.descriptionId}
          {...context.getFloatingProps(props)}
        >
          {props.children}
        </CardWrapper>
      </FloatingFocusManager>
    </FloatingPortal>
  )
})

/**
 * Component for rendering the popover heading. Will set `aria-labelledby` on the root Popover element if this component is mounted inside it.
 *
 * @param {React.ReactNode} props.children - Heading content.
 * @returns {JSX.Element} - The popover heading.
 */

export const PopoverHeading = forwardRef(function PopoverHeading(props, ref) {
  const { setLabelId } = usePopoverContext()
  const id = useId()

  useLayoutEffect(() => {
    setLabelId(id)
    return () => setLabelId(undefined)
  }, [id, setLabelId])

  return (
    <CardTitle {...props} ref={ref} id={id}>
      {props.children}
    </CardTitle>
  )
})

/**
 * Component for rendering the popover description. Will set `aria-labelledby` on the root Popover element if this component is mounted inside it.
 *
 * @param {React.ReactNode} props.children - Description content.
 * @returns {JSX.Element} - The popover description.
 */

export const PopoverDescription = forwardRef(function PopoverDescription(props, ref) {
  const { setDescriptionId } = usePopoverContext()
  const id = useId()

  useLayoutEffect(() => {
    setDescriptionId(id)
    return () => setDescriptionId(undefined)
  }, [id, setDescriptionId])

  return <div {...props} ref={ref} id={id} />
})

export const PopoverClose = forwardRef(function PopoverClose(props, ref) {
  const { setOpen } = usePopoverContext()
  return (
    <Button
      ref={ref}
      {...props}
      onClick={(event) => {
        props.onClick?.(event)
        setOpen(false)
      }}
    />
  )
})

/**
 * Component for rendering the popover footer.
 *
 * @param {React.ReactNode} props.children - Footer content, typically the PopoverCancel and PopoverConfirm components.
 * @returns {JSX.Element} - The popover footer.
 */

export const PopoverFooter = (props) => {
  const { children } = props

  return (
    <Flex className='dialog-footer' justifyContent='flex-end' alignItems='center' {...props}>
      {children}
    </Flex>
  )
}

/**
 * Component for rendering a cancel button in PopoverFooter.
 *
 * @param {string} [props.label='Cancel'] - Label for the cancel button.
 * @returns {JSX.Element} - A cancel button.
 */

export const PopoverCancel = (props) => {
  const { label } = props
  const { setOpen } = usePopoverContext()

  return (
    <Button
      onClick={() => setOpen(false)}
      {...props}
      p={3}
    >
      {label ?? 'Cancel'}
    </Button>
  )
}

/**
 * Component for rendering a confirm button in PopoverFooter.
 *
 * @param {function} [props.onConfirm] - Callback function for the confirm action.
 * @param {string} [props.label='OK'] - Label for the confirm button.
 * @returns {JSX.Element} - A confirm button.
 */

export const PopoverConfirm = (props) => {
  const { onConfirm, label } = props
  const { setOpen } = usePopoverContext()

  return (
    <Button
      onClick={() => onConfirm ? onConfirm() : setOpen(false)}
      {...props}
      variant='primary'
    >
      {label ?? 'OK'}
    </Button>
  )
}