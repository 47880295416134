import { Icon, Text, Flex } from '@lonerooftop/kitt-ui'
import { logout } from '@lonerooftop/kitt-data'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { FaUser, FaQuestionCircle, FaExternalLinkAlt } from 'react-icons/fa'

import { buildMenuLink } from './menu'
import { Tooltip } from '../tooltip'
import { DropdownMenu } from '../dropdown'
import { useDarkmode } from '../../hooks/use-preferences'
import { useConfigurableFeatureFlags } from '../../hooks/use-preferences'
import { captureUserLogout, reset } from '../../utils/analytics'
import features from '../../utils/features'

const TOGGLE = {
  dark: 'light',
  light: 'dark'
}

const PATHS = {
  videoTutorials: '/videos',
  helpCenter: 'https://hubstarpresenceiq.zendesk.com/hc/en-us',
  supportPortal: 'https://hubstarpresenceiq.zendesk.com/hc/en-us/requests/new',
  favorites: '/user/favorites',
  zones: '/tools/zones',
  upload: '/tools/upload',
  timemarkers: '/tools/timemarkers',
  uptime: '/tools/uptime',
  preferences: '/user/settings/preferences',
  buildingSettings: '/user/settings/building',
  featureFlags: '/user/settings/feature-flags',
  design: '/test/design',
}

export const HelpMenu = () => {
  const navigate = useNavigate()

  const helpMenuItems = [
    {
      title: <MenuLinkItem path={PATHS.videoTutorials} title='Video Tutorials' />,
      key: 'video-tutorials',
      onClick: () => navigate(PATHS.videoTutorials),
    },
    {
      title: <MenuExternalLinkItem title='Help Center' />,
      key: 'help-center',
      onClick: () => window.open(PATHS.helpCenter, '_blank', 'noopener,noreferrer'),
    },
    {
      title: <MenuExternalLinkItem title='Support Portal' />,
      key: 'support-portal',
      onClick: () => window.open(PATHS.supportPortal, '_blank', 'noopener,noreferrer'),
    }
  ]

  return (
    <HeaderMenu title='Help' menuItems={helpMenuItems} icon={<FaQuestionCircle />} />
  )
}

export const AdminMenu = () => {
  const navigate = useNavigate()
  const [ query ] = useSearchParams()
  const hasConfigurableFeatureFlags = useConfigurableFeatureFlags()
  const [ darkmode = 'light', setDarkmode ] = useDarkmode()

  const adminMenuItems = [
    {
      title: <MenuLinkItem path={PATHS.favorites} title='Favorites' />,
      key: 'favorites',
      onClick: () => navigate(PATHS.favorites),
    },
    {
      title: <MenuLinkItem path={PATHS.zones} title='Zones' />,
      key: 'zones',
      onClick: () => navigate(PATHS.zones),
    },
    {
      title: <MenuLinkItem path={PATHS.upload} title='Upload' />,
      key: 'upload',
      onClick: () => navigate(PATHS.upload),
      showItem: features.enabled('upload-data')
    },
    {
      title: <MenuLinkItem path={PATHS.timemarkers} title='Time Markers' />,
      key: 'time-markers',
      onClick: () => navigate(PATHS.timemarkers),
    },
    {
      title: <MenuLinkItem path={PATHS.uptime} title='Uptime' />,
      key: 'uptime',
      onClick: () => navigate(buildMenuLink({ to: PATHS.uptime }, query)),
    },
    {
      title: `Switch to ${TOGGLE[darkmode]} mode`,
      key: 'light-dark-mode',
      onClick: () => setDarkmode(TOGGLE[darkmode]),
    },
    null,
    {
      title: <MenuLinkItem path={PATHS.preferences} title='Personal preferences' />,
      key: 'personal-preferences',
      onClick: () => navigate(PATHS.preferences),
    },
    {
      title: <MenuLinkItem path={PATHS.buildingSettings} title='Building settings' />,
      key: 'building-settings',
      onClick: () => navigate(PATHS.buildingSettings),
    },
    {
      title: <MenuLinkItem path={PATHS.featureFlags} title='Feature flags' />,
      key: 'feature-flags',
      onClick: () => navigate(PATHS.featureFlags),
      showItem: hasConfigurableFeatureFlags
    },
    {
      title: <MenuLinkItem path={PATHS.design} title='Design page' />,
      key: 'design-page',
      onClick: () => navigate(PATHS.design),
      showItem: hasConfigurableFeatureFlags
    },
    {
      title: 'Logout',
      key: 'logout',
      onClick: async () => {
        try {
          // Wait for all async posthog stuff to resolve
          await captureUserLogout()
          await reset()
          window.localStorage.clear()
          // Logout does a reload immediately after
          logout()
        } catch (error) {
          logout()
        }
      },
    }
  ]

  return (
    <HeaderMenu title='Admin' menuItems={adminMenuItems} icon={<FaUser />} />
  )
}

const MenuLinkItem = (props) => {
  const { path, title } = props
  const { pathname } = useLocation()

  return <Text className={pathname === path ? 'header-menu-current-page' : ''}>{title}</Text>
}

const MenuExternalLinkItem = (props) => {
  const { title } = props

  return (
    <Flex width={100} justifyContent='space-between'>
      <Text pr={3}>{title}</Text>
      <FaExternalLinkAlt />
    </Flex>
  )
}

const HeaderMenu = (props) => {
  const { title, menuItems, icon } = props
  const activeMenuItems = menuItems.filter(item => item === null || !('showItem' in item) || item.showItem)

  return (
    <Tooltip as='span' title={title}>
      <DropdownMenu
        items={activeMenuItems.map(item => item ? { title: item.title, key: item.key } : null)}
        placement='bottom-end'
        transparent
        indicateSelected={false}
        forceTitle
        onChange={(key) => activeMenuItems.find(item => item?.key === key).onClick()}
        title={
          <Icon aria-label={`${title} menu`} display='flex'>
            {icon}
          </Icon>
        }
      />
    </Tooltip>
  )
}
